import React, { useState, useRef, useEffect } from 'react';

const AudioPlayer = ({ text, audioSrc, timestamps }) => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [highlightEnabled, setHighlightEnabled] = useState(false);  // changed from true to false
  const audioRef = useRef();

  useEffect(() => {
    const handleTimeUpdate = () => {
      const currentTime = audioRef.current.currentTime;
      let newIndex = timestamps.findIndex((time, index) => {
        return currentTime >= time && currentTime < timestamps[index + 1];
      });
      if (newIndex === -1) newIndex = timestamps.length - 1;
      setCurrentWordIndex(newIndex);
    };

    const audioElement = audioRef.current;
    audioElement.addEventListener('timeupdate', handleTimeUpdate);
    return () => {
      audioElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [timestamps]);

  const toggleHighlight = () => {
    setHighlightEnabled(!highlightEnabled);
  };

  const sections = text.split('\n\n').map((section, i) => {
    const isHeader = section.startsWith('<h') && section.includes('</h');
    const words = isHeader
      ? section.match(/<[^>]*>[^<]*<\/[^>]*>|\S+/g)
      : section.split(' ');
    return { words, isHeader };
  });

  let wordIndex = 0;
  return (
    <div className="audio-player-container">
      <div className="audio-text">
        {sections.map((section, i) => {
          if (section.isHeader) {
            const headerType = section.words[0].substring(1, 3);
            const headerText = section.words
              .map((word) => word.replace(/<\/?[^>]+(>|$)/g, ''))
              .join(' ');
            return (
              <h3
                key={i}
                className={`text-center ${
                  highlightEnabled && wordIndex === currentWordIndex
                    ? 'highlighted'
                    : ''
                }`}
              >
                {section.words.map((word, index) => {
                  const isHighlighted =
                    highlightEnabled && wordIndex === currentWordIndex;
                  wordIndex++;
                  if (index === 0) {
                    return (
                      <span
                        key={index}
                        className={headerType}
                        style={isHighlighted ? {backgroundColor: 'yellow'} : {}}
                      >
                        {word.replace(/<\/?[^>]+(>|$)/g, '')}{' '}
                      </span>
                    );
                  } else {
                    return (
                      <span
                        key={index}
                        style={isHighlighted ? {backgroundColor: 'yellow'} : {}}
                      >
                        {word.replace(/<\/?[^>]+(>|$)/g, '')}{' '}
                      </span>
                    );
                  }
                })}
              </h3>
            );
          } else {
            return (
              <div key={i}>
                {section.words.map((word, index) => {
                  const isHighlighted =
                    highlightEnabled && wordIndex === currentWordIndex;
                  wordIndex++;
                  return (
                    <span
                      key={wordIndex}
                      style={isHighlighted ? {backgroundColor: 'yellow'} : {}}
                    >
                      {word}{' '}
                    </span>
                  );
                })}
              </div>
            );
          }
        })}
      </div>
      <div className="fixed-audio-player">
        <audio ref={audioRef} src={audioSrc} controls />
        <button onClick={toggleHighlight}>Highlight</button>
      </div>
    </div>
  );
};

export default AudioPlayer;
