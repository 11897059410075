import React, { useEffect, useState, useCallback } from "react";
import "../../components/Progress/Progress-scroll.css";
import { useAuth } from "../useAuth";
// import { getApps, initializeApp } from 'firebase/app';
// import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
// import clientCredentials from '../../js/firebase'; // update with the correct path

// let firebaseApp;
// if (!getApps().length) {
//     firebaseApp = initializeApp(clientCredentials);
// } else {
//     firebaseApp = getApps()[0]; // if already initialized, use that one
// }
// const db = getFirestore(firebaseApp);

const ScrollIndicator = () => {
  const [scrollTop, setScrollTop] = useState(0);
  const [finishedChapter, setFinishedChapter] = useState(false);
  const { user, isAuthenticated } = useAuth();
  const userId = user?.uid;

  const onScroll = useCallback(async () => {
    if (!isAuthenticated) return;

    const winScroll = document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = (winScroll / height) * 100;
    setScrollTop(scrolled);

    // Uncomment below code when you want to use Firebase
    // if (scrolled >= 97) {
    //   const currentUrl = window.location.href;
    //   const urlId = currentUrl.match(/^.*?\/\/.*?\/([^/]+\/[^/]+\/[^/]+).*$/)[1];

    //   // Saving progress to Firestore
    //   await setDoc(doc(db, 'progress', userId), {
    //     [urlId]: true,
    //   }, { merge: true });

    // }
  }, [isAuthenticated, userId]);

  useEffect(() => {
    if (isAuthenticated) {
      window.addEventListener("scroll", onScroll);
      return () => window.removeEventListener("scroll", onScroll);
    }
  }, [isAuthenticated, onScroll]);

  // Commented Firebase code
  // useEffect(() => {
  //   if (!userId) return;

  //   const currentUrl = window.location.href;
  //   const urlId = currentUrl.match(/^.*?\/\/.*?\/([^/]+\/[^/]+\/[^/]+).*$/)[1];

  //   // Fetch progress from Firestore
  //   const fetchProgress = async () => {
  //     const progressDoc = doc(db, 'progress', userId);
  //     const docSnap = await getDoc(progressDoc);
  
  //     const finished = docSnap.exists() && docSnap.data()[urlId];
  //     setFinishedChapter(finished);
  //   };

  //   fetchProgress();
  // }, [userId]);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div>
      <div className="progressMainWrapper">
        <div
          className="progressMainStyle"
          style={{
            width: finishedChapter ? "100%" : `${scrollTop}%`,
            backgroundColor: "green",
          }}
        ></div>
      </div>
    </div>
  );
};

export default ScrollIndicator;
