import React from "react";
import { Row, Col, Container } from "reactstrap";
import { Router, Route, Switch, useHistory } from "react-router-dom";
import Card from "../../components/card";
import EpistemologyIntroImage from '../../assets/epistemology.svg';
import EpistemologyIntro from "./epistemology_introduction/epistemology_introduction_course_index";
import EpistemologyIntroIntroduction from "./epistemology_introduction/epistemology_introduction_introduction";
import EpistemologyIntroChapter1 from "./epistemology_introduction/epistemology_introduction_chapter1";
import EpistemologyIntroChapter2 from "./epistemology_introduction/epistemology_introduction_chapter2";
import Quiz1 from "./epistemology_introduction/epistemology_introduction_quiz1";
import Loading from "../../components/Loading";
import { useAuth } from "../../components/useAuth";
import history from "../../js/history";
import { getProgress } from "../../components/Progress/Progress-check";
import { useTranslation } from "react-i18next";
import { configureI18n } from '../../components/i18nConfig';
import toast from "react-hot-toast";

configureI18n("/locales/homepage", "translation");

const Epistemology = () => {
  const history = useHistory()

  const { isLoading, role } = useAuth();

  if (isLoading) {
    return <Loading />;
  }
  // use same withotu basic one to check for the philosopher course

  return (
    <div className="next-steps my-5">
      <Router history={history}>
        <Container className="flex-grow-1 mt-5">
          <Switch>
            <Route exact path="/Epistemology" component={EpistemologyHome} />
            <Route path="/Epistemology/Epistemology-intro" component={EpistemologyIntro} />
            <Route path="/Epistemology/Epistemology-intro/introduction" component={EpistemologyIntroIntroduction} />
            <Route path="/Epistemology/Epistemology-intro/chapter1" component={EpistemologyIntroChapter1} />
            <Route path="/Epistemology/Epistemology-intro/chapter2" component={EpistemologyIntroChapter2} />
            <Route path="/Epistemology/Epistemology-intro/Quiz1" component={Quiz1} />
          </Switch>
        </Container>
      </Router>
    </div>
  );
};

const EpistemologyHome = () => {



  const { t } = useTranslation();

  const { isAuthenticated, role } = useAuth();

  const keys = Object.keys(localStorage).filter(key => key !== 'urls');
  console.log(keys);
  const progress = keys.length * 10;
  const progressString = `Progress: ${getProgress('/Ethics/Ethics-intro/chapter1') + progress}%`;
  const progressNumber = parseInt(progressString.split(':')[1]);




  return (
    <div className='next-steps my-5'>
      <h2 className='my-5 text-center'>{t("EpistemologyCoursesPageTitle")}</h2>
      <Row className='d-flex justify-content-between'>
        <Col md={6} className='mb-4'>
          <Card
            value={isAuthenticated ? progressNumber : null}
            link='/Epistemology/Epistemology-intro'
            image={EpistemologyIntroImage}
            title={t("EpistemologyCoursesPageIntroCourseTitle")}
            isAuthenticated={isAuthenticated}
            className='mb-3'
          />
        </Col>
      </Row>
    </div>
  );
};

export default Epistemology;
