import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./js/serviceWorker";
import { AuthProvider } from "./components/AuthContext";
import { Provider } from 'react-redux'; // import Provider
import store from "./components/store";
import App from "./App";

ReactDOM.render(
  <Provider store={store}> {/* Add Provider */}
    <AuthProvider>
      <App />
    </AuthProvider>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.register();
