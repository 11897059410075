import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, CardTitle, ListGroup, ListGroupItem, Collapse, Button } from "reactstrap";
import portraitVideo from "../assets/wilmer.mp4";
import LazyLoad from "react-lazyload"; // Import LazyLoad

class About extends Component {
  state = {
    certificates: [

      {
        id: 1,
        title: "Epistemology",
        institution: "University of Warsaw",
        date: "2021-06-15",
        image: "path/to/certificate1.jpg",
      },
      {
        id: 2,
        title: "Introduction to Philosophy",
        institution: "University of Example",
        date: "2021-06-15",
        image: "path/to/certificate1.jpg",
      },
      
      {
        id: 3,
        title: "Introduction to Philosophy",
        institution: "University of Example",
        date: "2021-06-15",
        image: "path/to/certificate1.jpg",
      },
      {
        id: 4,
        title: "Introduction to Philosophy",
        institution: "University of Example",
        date: "2021-06-15",
        image: "path/to/certificate1.jpg",
      },
      // Add more certificates as needed
    ],
    articles: [
      {
        id: 1,
        title: "Transhuman critique of Husserlian phenomenology",
        url: "https://www.researchgate.net/publication/331047657_Transhuman_critique_of_Husserlian_phenomenology",
      },
      // Add more articles as needed
    ],
    gradesVisible: false,
  };

  toggleGradesVisibility = () => {
    this.setState((prevState) => ({
      gradesVisible: !prevState.gradesVisible,
    }));
  };
  

  render() {
    const { certificates, articles, gradesVisible } = this.state;

    return (
      <Container className="about-container">
        <h2>About Me</h2>
        <LazyLoad height={100} offset={10}>
          <video loop autoPlay muted playsInline>
            <source src={portraitVideo} type="video/mp4" />
          </video>
        </LazyLoad>

        <Button style={{margin: '20px auto 20px'}} color="primary" onClick={() => window.location.href="https://calendly.com/philosophiaacademy/custom-philosophy-course"}>Get a Custom Course</Button>

        <h2>Articles I've Written</h2>
        <ListGroup>
          {articles.map((article) => (
            <ListGroupItem key={article.id}>
              <a href={article.url} target="_blank" rel="noopener noreferrer">
                {article.title}
              </a>
            </ListGroupItem>
          ))}
        </ListGroup>
      </Container>
    );
  }
}

export default About;
