import React, { useState, useEffect } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from './useAuth';
import i18n from './i18nConfig';
import logo from "../assets/lyceum.svg";
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
//import { useDispatch } from "react-redux";
//import { login, logout } from "./userSlice";

import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);
  const { user, isAuthenticated, login, logout } = useAuth();

  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    const handleResize = () => setIsDesktop(window.innerWidth >= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const logoutWithRedirect = () => {
    logout({ returnTo: window.location.origin })
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  }

  const LanguageSelector = () => (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav>
        <FontAwesomeIcon icon={faLanguage} size="3x"/>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => changeLanguage('en')}>
          English
        </DropdownItem>
        <DropdownItem onClick={() => changeLanguage('sv')}>
          Svenska
        </DropdownItem>
        <DropdownItem onClick={() => changeLanguage('jp')}>
          日本語
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const HomeAboutLinks = (
    <Nav className="nav-links mr-auto" navbar>
      <NavItem>
        <NavLink
          className="left-side"
          tag={RouterNavLink}
          to="/"
          exact
          activeClassName="router-link-exact-active"
        >
          Home
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className="left-side"
          tag={RouterNavLink}
          to="/about"
          exact
          activeClassName="router-link-exact-active"
        >
          About
        </NavLink>
      </NavItem>
    </Nav>
  );

  return (
    <div className="nav-container">
      <Navbar color="light" light expand="md">
        <Container>
          <div className="d-flex justify-content-between w-100">
            <div className="d-flex align-items-center">
              <NavLink className="left-side" tag={RouterNavLink} to="/">
                <img alt="" src={logo} width={160}/>
              </NavLink>
              {isDesktop && HomeAboutLinks}
            </div>
            <LanguageSelector />
            <NavbarToggler onClick={toggle} />
          </div>
          <Collapse isOpen={isOpen} navbar>
            {!isDesktop && HomeAboutLinks}
            <Nav className="d-flex align-items-center" navbar>
              {!isAuthenticated && (
                <Button
                  id="qsLoginBtn"
                  color="primary"
                  className="btn-margin"
                  onClick={() => login({ redirect_uri: window.location.origin })}
                >
                  Login
                </Button>
              )}
              {isAuthenticated && (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret id="profileDropDown">
                    <img
                      src={user?.photoURL}
                      alt="Profile"
                      className="nav-user-profile rounded-circle"
                      width="50"
                    />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem header>{user?.displayName}</DropdownItem>
                    <DropdownItem
                      tag={RouterNavLink}
                      to="/profile"
                      className="dropdown-profile"
                      activeClassName="router-link-exact-active"
                    >
                      <FontAwesomeIcon icon="user" className="mr-3" /> Profile
                    </DropdownItem>
                    <DropdownItem
                      id="qsLogoutBtn"
                      onClick={logoutWithRedirect}
                    >
                      <FontAwesomeIcon icon="power-off" className="mr-3" /> Log out
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </div>
  );

};

export default NavBar;
