import React from 'react';
import useAuth from './useAuth';
import { loadCheckout } from './useAuth';
import Socrates from "../assets/Socrates.jpg"


function Products() {
  const { products, loadCheckout, role } = useAuth();

  // Check if the products object is empty, if so display a loading message
  if (Object.keys(products).length === 0) {
    return <div>Loading products...</div>;
  }

  // If there are products, display them
  return (
    <div>
      {Object.entries(products).map(([productId, productData]) => {
        return (
          <div key={productId}>
            <h5>{productData.name}</h5>
            <img src={Socrates} alt="Product" />
            <h5>{productData.description}</h5>
            <button onClick={() => loadCheckout(productData.prices.priceId)} disabled={role === 'Basic' || role === 'Premium'}  >Buy Now</button>
          </div>
        );
      })

      }
    </div>
  );
}


export default Products;