import { getApps, initializeApp } from 'firebase/app';
import { getFirestore, } from 'firebase/firestore';
import { getFunctions, httpsCallable } from "firebase/functions"

const clientCredentials = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
let app;

if (!getApps().length) {
  app = initializeApp(clientCredentials);


} else {
  app = getApps()[0];
}

const functions = getFunctions(app, 'asia-northeast1');
export const createPortalLink = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');

export const db = getFirestore()

export default app
