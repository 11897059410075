import React, { useEffect, useState } from "react";
import { Col, NavLink } from 'reactstrap';
import { NavLink as RouterNavLink } from "react-router-dom";
import ScrollIndicator from "../../../components/Progress/Progress-scroll";
import AudioPlayer from '../../../components/AudioPlayer';
import { useTranslation } from "react-i18next";
import { configureI18n } from '../../../components/i18nConfig';

configureI18n("/locales/homepage", "intro_epistemology_intro");
configureI18n("/locales/homepage", "CourseNav");

const EpistemologyIntroIntroduction = () => {

  const { t: t2 } = useTranslation('CourseNav');

  const { t, i18n } = useTranslation('intro_epistemology_intro');
  const [audioSrc, setAudioSrc] = useState(null);

  // Same timestamps for every language
  const wordTimestamps = [0.0, 0.25, 0.60, 1.30, 1.65, 1.83, 2.22, 3.33, 4.03, 4.29, 4.400, 4.71, 4.89, 5.0, 5.28, 5.47, 5.86, 7.03, 7.43, 7.59, 8.55, 8.87, 9.09, 9.75, 10.05, 10.46, 10.63, 11.16, 11.63, 11.83, 12.05, 12.57, 13.49, 13.89, 14.52, 15.19, 15.51, 15.73, 15.91, 16.46, 16.79, 17.63, 17.98, 18.08, 18.38, 18.55, 18.71, 19.15, 19.67, 19.83, 20.2, 20.45, 20.97, 21.25, 21.83, 23.33, 23.67, 24.17, 24.7, 24.8, 24.99, 25.36, 25.71, 25.87, 26.03, 26.19, 26.35, 26.66, 26.89, 27.58, 27.93, 28.39, 28.65, 29.43, 30.32, 31.33, 31.76, 32.81, 33.15, 33.31, 33.47, 33.76, 34.27, 34.51, 34.73, 35.07, 35.23, 35.39, 35.55, 35.71, 36.27, 36.51, 36.67, 36.89, 37.07, 37.38, 37.55, 37.77, 38.52, 38.96, 39.6, 40.43, 41.0, 41.23, 41.45, 42.65, 43.33, 43.94, 44.83, 45.2, 45.64, 45.74, 46.04, 46.21, 46.43, 46.9, 47.54, 47.73, 47.89, 48.33, 49.21, 49.62, 50.1, 50.5, 50.87, 51.03, 51.47, 52.06, 52.29, 53.0, 53.51, 53.73, 53.97, 54.08, 54.59, 54.85, 55.51, 55.9, 56.2, 56.6, 56.79, 57.08, 57.8, 58.23, 58.39, 58.55, 58.71, 59.24, 59.85, 60.87, 61.26];

  // Dynamic audio file import based on the current language
  useEffect(() => {
    const language = i18n.language;
    import(`../epistemology_introduction/assets/audio-files/epistemology_intro_${language}.mp3`)
      .then((audio) => {
        setAudioSrc(audio.default);
      })
      .catch((error) => console.error(`Failed to load audio: ${error}`));
  }, [i18n.language]);

  if (!audioSrc) return null;

  return (
    <Col sm={{ size: 'auto', offset: 1 }}>
      <ScrollIndicator />
      <br />
      <div className="course-paragraph">
        <AudioPlayer
            text={t('intro_epistemology_intro')}
            audioSrc={audioSrc}
            timestamps={wordTimestamps}
        />
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <RouterNavLink to="/Epistemology/Epistemology-intro">
                  <NavLink>{t2("NavCourseOutline")}</NavLink>
            </RouterNavLink>
            <RouterNavLink to="/Epistemology/Epistemology-intro/chapter1">
                  <NavLink>{t2("NavChapter1")}</NavLink>
            </RouterNavLink>
        </div> 
      </div>
    </Col>
  );
};

export default EpistemologyIntroIntroduction;
