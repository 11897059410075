import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Container } from 'reactstrap';
import history from "../../../js/history";
import PhilosophyOfMindIntroNav from "./nav/philosophyofmind_introduction_nav"
import PhilosophyOfMindIntroIntroduction from "../../../courses/philosophyofmind/philosophyofmind_introduction/philosophyofmind_introduction_introduction";
import PhilosophyOfMindIntroChapter1 from "../../../courses/philosophyofmind/philosophyofmind_introduction/philosophyofmind_introduction_chapter1";
import PhilosophyOfMindIntroChapter2 from "../../../courses/philosophyofmind/philosophyofmind_introduction/philosophyofmind_introduction_chapter2";
import Quiz1 from "../../../courses/philosophyofmind/philosophyofmind_introduction/philosophyofmind_introduction_quiz1";
import IntroCard from "../../../components/IntroCard";
import Sartre from "./assets/sartre.jpg";
import GEMoore from "./assets/GE-moore.jpg";

const PhilosophyOfMindTest = () => {

  return (
    <Router history={history}>
      <PhilosophyOfMindIntroNav />
      <Switch>
        <Route exact path="/Philosophy-of-mind/Philosophy-of-mind-intro" component={PhilosophyOfMindIntro} />
        <Route path="/Philosophy-of-mind/Philosophy-of-mind-intro/introduction" component={PhilosophyOfMindIntroIntroduction} />  
        <Route path="/Philosophy-of-mind/Philosophy-of-mind-intro/chapter1" component={PhilosophyOfMindIntroChapter1} /> 
        <Route path="/Philosophy-of-mind/Philosophy-of-mind-intro/quiz1" component={Quiz1} />
        <Route path="/Philosophy-of-mind/Philosophy-of-mind-intro/chapter2" component={PhilosophyOfMindIntroChapter2} />
      </Switch>
    </Router>
  );
};

const PhilosophyOfMindIntro = () => {

  return (
  <div className="intro-container">
    <Container className="timeline-container">
    <h2 className="text-center">Course introduction</h2>
    <p className="text-center">Welcome to this introductory course in epistemology</p>
    <h3 className="text-center">Course overview</h3>
        <div className="timeline timeline-centered">
          <IntroCard 
            chapter={"chapter 1"} 
            image={Sartre}
            title={"Chapter 1: Trying to Define Knowledge"}
            text={"Delve into Gettier problems, which question the traditional justified true belief (JTB) model in epistemology. Analyze thought experiments and explore potential solutions to better comprehend the nature of knowledge."} 
          />
          <IntroCard 
            chapter={"chapter 2"} 
            image={GEMoore}
            title={"Chapter 2: Skepticism"}
            text={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud."} 
          />
          <IntroCard 
            chapter={"chapter 3"} 
            image={Sartre}
            title={"Chapter 3: The Structure of Knowledge"}
            text={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud."} 
          />
          <IntroCard 
            chapter={"chapter 4"} 
            image={Sartre}
            title={"Utilitarianism"}
            text={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud."} 
          />
        </div>
    </Container>
  </div>
  )
}

export default PhilosophyOfMindTest;