import React from "react";
import { Row, Col, Jumbotron} from "reactstrap";
import Card from "./card";
import PhilosopherCard from "./philosopherCard";
import MetaphysicsImage from '../assets/metaphysics.svg';
import EpistemologyImage from '../assets/epistemology.svg';
import EthicsImage from '../assets/ethics.svg';
import AestheticsImage from '../assets/aesthetics.svg';
import PhilosophyOfMindImage from '../assets/philosophyofmind.svg';
import PoliticalPhilosophyImage from '../assets/PoliticalPhilosophy.svg';
import PhilosophyOfScienceImage from '../assets/philosophyofscience.svg';
import bgImage1 from "../assets/bg1.svg";
import Sartre from "../assets/sharedAssets/sartre.mp4";
import MichelFoucault from "../assets/sharedAssets/MichelFoucault.mp4";
//import RobertNozick from "../assets/sharedAssets/RobertNozick.mp4";
import GEMoore from "../assets/sharedAssets/GEMoore.mp4";
import FriedrichNietzsche from "../assets/sharedAssets/FriedrichNietzsche.mp4";
import { useTranslation } from "react-i18next";
import { configureI18n } from './i18nConfig';
import { useAuth } from './useAuth'; // Assuming you have this custom hook for Firebase Auth

configureI18n("/locales/homepage", "translation");

  
const Content = () => {

  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();

    return (

      <div className="container">
        <Jumbotron className="test1" style={{ backgroundImage: `url(${bgImage1})`, backgroundSize: 'cover' }}/>
        <div className="next-steps my-5">
          <h2 className="my-5 text-center">{t("Courses")}</h2>

          <Row className="d-flex justify-content-between">
            <Col md={4} className="mb-5">
                <Card 
                  link="Metaphysics" 
                  image={MetaphysicsImage} 
                  title={t("MetaphysicsCourseTitle")}
                  text={t("MetaphysicsCourseText")}
                  isAuthenticated={isAuthenticated} 
                  className="mb-3"/>
            </Col>
            <Col md={4} className="mb-5">
              <Card 
                link="Epistemology" 
                image={EpistemologyImage} 
                title={t("EpistemologyCourseTitle")}
                text={t("EpistemologyCourseText")}
                isAuthenticated={isAuthenticated}
                className="mb-3"/>
            </Col>
            <Col md={4} className="mb-5">
              <Card 
                link="Ethics" 
                image={EthicsImage} 
                title={t("EthicsCourseTitle")}
                text={t("EthicsCourseText")}
                isAuthenticated={isAuthenticated} 
                className="mb-3"/>
            </Col>
            <Col md={4} className="mb-5">
              <Card 
                link="Political-philosophy" 
                image={PoliticalPhilosophyImage} 
                title={t("PoliticalPhilosophyTitle")}
                text={t("PoliticalPhilosophyText")}
                isAuthenticated={isAuthenticated} 
                className="mb-3"/>
            </Col>
            <Col md={4} className="mb-5">
              <Card 
                link="philosophy-of-mind" 
                image={PhilosophyOfMindImage} 
                title={t("PhilosophyOfMindTitle")}
                text={t("PhilosophyOfMindText")}
                isAuthenticated={isAuthenticated}  
                className="mb-3"/>
            </Col>
            <Col md={4} className="mb-5">
              <Card 
                link="philosophy-of-science" 
                image={PhilosophyOfScienceImage} 
                title={t("PhilosophyOfScienceTitle")}
                text={t("PhilosophyOfMindText")}
                isAuthenticated={isAuthenticated}  
                className="mb-3"/>
            </Col>
            <Col md={4} className="mb-5">
              <Card 
                link="Aesthetics" 
                image={AestheticsImage} 
                title={t("AestheticsTitle")}
                text={t("AestheticsText")}
                isAuthenticated={isAuthenticated} 
                className="mb-3"/>
            </Col>
          </Row>
          <h2 className="my-5 text-center">{t("Philosophers")}</h2>
          <p className="text-center">{t("PhilosophersText")}</p>
          <Row className="d-flex justify-content-between">
            <Col md={3} className="mb-6">
                <PhilosopherCard
                  link="Metaphysics" 
                  video={FriedrichNietzsche} 
                  title="Metaphysics" 
                  text="Friedrich Nietzsche"
                  className="mb-6"/>
            </Col>
            <Col md={3} className="mb-6">
                <PhilosopherCard
                  link="Metaphysics" 
                  video={Sartre} 
                  title="Metaphysics" 
                  text="Jean-Paul Sartre"
                  className="mb-6"/>
            </Col>
            <Col md={3} className="mb-6">
                <PhilosopherCard
                  link="Metaphysics" 
                  video={MichelFoucault} 
                  title="Metaphysics" 
                  text="Michel Foucault"
                  className="mb-6"/>
            </Col>
            <Col md={3} className="mb-6">
                <PhilosopherCard
                  link="Metaphysics" 
                  video={GEMoore} 
                  title="Metaphysics" 
                  text="G. E. Moore"
                  className="mb-6"/>
            </Col>
          </Row>
          <Row className="d-flex justify-content-between">
            {/*{contentData.map((col, i) => (
              <Col key={i} md={5} className="mb-4">
                <h6 className="mb-3">
                  <a href={col.link}>
                    <FontAwesomeIcon icon="link" className="mr-2" />
                    {col.title}
                  </a>
                </h6>
               <p>{col.description}</p>
              </Col>
            ))}
            */}
          </Row>
        </div>
      </div>
    );
  }

export default Content;