import React, { Component } from 'react';
import { NavLink, Progress } from 'reactstrap';
import { NavLink as RouterNavLink } from 'react-router-dom';

class Card extends Component {
    render() {
        const { isAuthenticated } = this.props;

        // If the user is not authenticated, don't show the progress
        const progressInfo = isAuthenticated ? (
            <Progress animated striped value={this.props.value}></Progress>
        ) : null;

        return (
            <NavLink className="card text-center overlay" tag={RouterNavLink} to={this.props.link} exact>
                <div className="overflow">
                    <img className="card-img-top" src={this.props.image} alt="" />
                    {progressInfo}
                </div>
                <div className="card-body text-dark">
                    <h4 className="card-title">{this.props.title}</h4>
                    <p>{this.props.text}</p>
                    <button className="btn btn-outline-success">Go to courses</button>
                </div>
            </NavLink>
        );
    }
}

export default Card;
