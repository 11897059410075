import React, {} from "react";
import { Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop"
import Metaphysics from "./courses/metaphysics/metaphysics_index";
import Epistemology from "./courses/epistemology/epistemology_index";
import Aesthetics from "./courses/aesthetics/aesthetics_index";
import Ethics from "./courses/ethics/ethics_index";
import PhilosophyOfMind from "./courses/philosophyofmind/philosophyofmind_index";
import PoliticalPhilosophy from "./courses/political-philosophy/political-philosophy_index";
import PhilosophyOfScience from "./courses/philosophyofscience/philosophyofscience_index";
import Home from "./js/Home";
import About from "./js/About";
import Profile from "./js/Profile";
import history from "./js/history";
import "./App.css";
import initFontAwesome from "./js/initFontAwesome";
import toast, { Toaster } from 'react-hot-toast';


initFontAwesome();

const App = () => {

  return (
    <>
      <Toaster />
      <Router history={history}>
        <ScrollToTop />
        <div id="app" className="d-flex flex-column h-100">
          <NavBar />
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/about" component={About} />
              <Route path="/metaphysics" component={Metaphysics} />
              <Route path="/epistemology" component={Epistemology} />
              <Route path="/ethics" component={Ethics} />         
              <Route path="/aesthetics" component={Aesthetics} />
              <Route path="/philosophy-of-mind" component={PhilosophyOfMind} />
              <Route path="/political-philosophy" component={PoliticalPhilosophy} />
              <Route path="/philosophy-of-science" component={PhilosophyOfScience} />
              <PrivateRoute path="/profile" component={Profile} />
            </Switch> 
          <Footer />
        </div>
      </Router>
    </>
  );
};

export default App;

