import React from "react";
import QuizBee from "../../../components/quiz/index";
import { Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { configureI18n } from '../../../components/i18nConfig';

configureI18n("/locales/homepage", "QuizTitle");

const Quiz4 = (score) =>  {

  const { t } = useTranslation('QuizTitle');

  if (score === 4) {
    console.log(score);
    localStorage.setItem('Name', score);
  }


return (
  <Col className="quiz" sm={{ size: 'auto', offset: 1 }}>
    <h1>{t("QuizTitle4")}</h1>
    <QuizBee />
  </Col>
  )
}

export default Quiz4;