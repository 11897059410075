import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

export function configureI18n(loadPathPrefix) {
  i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
      supportedLngs: ["en", "sv", "jp"],
      fallbackLng: "en",
      detection: {
        order: ["cookie", "htmlTag", "localStorage", "path", "subdomain"],
        caches: ["cookie"]
      },
      backend: {
        loadPath: `${loadPathPrefix}/{{lng}}/{{ns}}.json`
      },
      ns: ["translation", "epistemology_introduction_course_index"],
      defaultNS: "translation",
      react: { useSuspense: false }
    });

  return i18n;
}



export default i18n;