import React from 'react';
import { Col, Row, NavLink } from 'reactstrap';
import { NavLink as RouterNavLink } from 'react-router-dom';

const IntroCard = props => {
    return (        
        <div className="timeline-item">
            <div className="chapter-info">
                <span><p>{props.chapter}</p></span>
            </div>
            <div className="timeline-marker"></div>
            <NavLink tag={RouterNavLink} to={props.link} exact style={{ color: 'black' }}>
                <Row>
                    <Col>
                        <img alt="" className="portrait" src={props.image} />
                        <h3 className="text-center">{props.title}</h3>
                        <p className="text-center">{props.text}</p>
                    </Col>
                </Row> 
            </NavLink>
        </div>
    );
}

export default IntroCard;
