import React, { useEffect, useState } from "react";
import { Col, NavLink } from 'reactstrap';
import { NavLink as RouterNavLink, useHistory } from "react-router-dom";
import QuizBee from "../../../components/quiz/index";
import { useTranslation } from "react-i18next";
import { configureI18n } from '../../../components/i18nConfig';
import useAuth from '../../../components/useAuth';
import toast from 'react-hot-toast';

configureI18n("/locales/homepage", "QuizTitle");
configureI18n("/locales/homepage", "CourseNav");

const Quiz1 = () => {
    const { role } = useAuth();
    console.log('Role outside click handler:', role);

    const history = useHistory();
    const { t: t2 } = useTranslation('CourseNav');

    const { t } = useTranslation('QuizTitle');
    const [questions, setQuestions] = useState([]);

    const { i18n } = useTranslation();

    useEffect(() => {
        const language = i18n.language; // Get current language
        const url = `${process.env.PUBLIC_URL}/course-quizes/epistemology_quizes/epistemology_Introduction_quizes/chapter1_quiz_${language}.json`;
        fetch(url)
            .then(response => response.json())
            .then(data => {
                console.log('Fetched data:', data);
                setQuestions(data);
            })
            .catch(error => console.error(error));
    }, [i18n.language]);

    const score = localStorage.getItem('Name');
    if (score === 4) {
        console.log(score);
    }
    
    const handleNavClick = (e) => {
        e.preventDefault();
        console.log("Current role:", role);
        if (role === 'Basic' || role === 'Premium') {
            history.push('/Epistemology/Epistemology-intro/chapter2');
        } else {
            toast.error('Subscribe to continue');
            history.push('/Profile');
        }
    }
    
    return (
        <Col className="quiz" sm={{ size: 'auto', offset: 1 }}>
            <h1>{t("QuizTitle1")}</h1>
            <QuizBee questions={questions} />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <RouterNavLink to="/Epistemology/Epistemology-intro/chapter1">
                    <NavLink>{t2("NavChapter1")}</NavLink>
                </RouterNavLink>

                <RouterNavLink onClick={handleNavClick} to="#">
                    <NavLink>{t2("NavChapter2")}</NavLink>
                </RouterNavLink>
            </div>
        </Col>
    )
}

export default Quiz1;