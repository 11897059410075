import React, {useEffect, useRef, useState} from "react";
import { gsap } from "gsap";

const QuestionBox = ({question, options, selected, isActive}) => {
    const questionBoxRef = useRef();

    useEffect(() => {
        if (isActive) {
            gsap.to(questionBoxRef.current, {opacity:1, duration:0.1, zIndex:1});
        } else {
            gsap.to(questionBoxRef.current, {opacity:0, duration:0.1, zIndex:-1});
        }
    }, [isActive]);

    const [answer, setAnswer] = useState(options);
    
    return (
        <div className="questionBox" ref={questionBoxRef}>
            <div className="question"><h1> {question} </h1></div>
            {answer.map((text, index) => (
                <button key={index} 
                    onBlur={(e) => {
                        console.log('Triggered because this input lost focus');
                    }}
                    onClick={() => {
                     setAnswer([text]);
                     selected(text);
                    }}
                >
                    {text} 
                </button>
            ))}
        </div>
    )
}
export default QuestionBox;
