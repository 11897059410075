import React, { useState, useEffect } from "react";
import QuestionBox from "./QuestionBox";
import Result from "./Result";

const QuizBee = ({questions}) => {

    const [questionBank, setQuestionBank] = useState(questions || []);
    const [score, setScore] = useState(0);
    const [responses, setResponses] = useState(0);

    useEffect(() => {
        setQuestionBank(questions);
    }, [questions]);

    const computeAnswer = (answer, correctAnswer) => {
        if (answer === correctAnswer) {
            setScore(score + 1);
        }
        setResponses(responses + 1);
    }

    const playAgain = () => {
        setQuestionBank(questions || []);
        setScore(0);
        setResponses(0);
    }

    return (
        <div className="container-quiz">
            {questionBank.length > 0 && responses < questionBank.length &&
                questionBank.map(({question, answers, correct, questionId}, index) => (
                    <QuestionBox 
                        question={question} 
                        options={answers} 
                        key={questionId}
                        selected={answer => computeAnswer(answer, correct)}
                        isActive={index === responses} // isActive is true for the current question
                    /> 
                ))
            }
            {responses > 0 && responses === questionBank.length && 
                <Result score={score} playAgain={playAgain} />
            }
        </div>
    );
}

export default QuizBee;
