import React from "react";
import { Col } from "reactstrap";
import Sartre from "../../../assets/sharedAssets/sartre.mp4";
import ScrollIndicator from "../../../components/Progress/Progress-scroll";
import AudioPlayer from '../../../components/AudioPlayer';
import audiophil from "../philosophyofscience_introduction/assets/audio.mp3";


const PhilosophyOfScienceIntroIntroduction = () => {

const wordTimestamps = [0,0.0839,0.5259,0.9678,1.4098,1.8517,2.2937,2.7356,3.1776,3.6195,4.0615,4.5034,4.9454,5.3873,5.8293,6.2712,6.7132,7.1551,7.5971,8.039,8.481,8.9229,9.3649,9.8068,10.2488,10.6907,11.1327,11.5746,12.0166,12.4585,12.9005,13.3424,13.7844,14.2263,14.6683,15.1102,15.5522,15.9941,16.4361,16.878,17.32,17.7619,18.2039,18.6458,19.0878,19.5297,19.9717,20.4136,20.8556,21.2976,21.7395,22.1815,22.6234,23.0654,23.5073,23.9493,24.3912,24.8332,25.2751,25.7171,26.159,26.601,27.0429,27.4849,27.9268,28.3688,28.8107,29.2527,29.6946,30.1366,30.5785,31.0205,31.4624,31.9044,32.3463,32.7883,33.2302,33.6722,34.1141,34.5561,34.998,35.44,35.8819,36.3239,36.7658,37.2078,37.6497,38.0917,38.5336,38.9756,39.4175,39.8595,40.3014,40.7434,41.1853,41.6273,42.0692,42.5112,42.9532,43.3951,43.8371,44.279,44.721,45.1629,45.6049,46.0468,46.4888,46.9307,47.3727,47.8146,48.2566,48.6985,49.1405,49.5824,50.0244,50.4663,50.9083,51.3502,51.7922,52.2341,52.6761,53.118,53.56,54.0019,54.4439,54.8858,55.3278,55.7697,56.2117,56.6536,57.0956,57.5375,57.9795,58.4214,58.8634,59.3053,59.7473,60.1892,60.6312,61.0731,61.5151,61.957,62.399,62.8409,63.2829,63.7248,64.1668,64.6088,65.0507,65.4927,65.9346,66.3766,66.8185,67.2605,67.7024,68.144];
const text = "Welcome to the captivating realm of epistemology! In this beginner-friendly guide, we'll delve into the world of epistemology, often referred to as \"the theory of knowledge.\" Our goal is to understand the nature of knowledge and how we know what we think we know. The term \"epistemology\" comes from the Greek words \"episteme\" and \"logos.\" \"Episteme\" can be translated as \"knowledge,\" \"understanding,\" or \"acquaintance,\" while \"logos\" can be translated as \"account,\" \"argument,\" or \"reason.\"\nAs we begin our journey, you might be wondering what we mean by \"know\"? How can we trust our senses? These are just a few of the many questions we'll address as we dive into the study of epistemology.\nTo start, it's essential to know that when we talk about knowledge in epistemology, we're generally referring to \"propositional knowledge.\" Propositional knowledge consists of knowing propositions—statements that can be either true or false and serve as the basic building blocks of logical reasoning.\nAs mentioned earlier, propositional knowledge is about knowing facts or truths about the world around us. Each fact or truth we know can be expressed as a proposition—a statement that is either true or false.\n\nHere are some examples of Propositional knowledge:\n1. Earth is round (sorry Flat Earthers)\n2. Knowing that: 2 + 2 = 4.\n\nKeep in mind, there are other ways to \"know\" things too, like knowing a person, a place, or even how to do something. However, in epistemology, we're primarily concerned with the nature of \"knowledge\" as it relates to propositional knowledge.\nSo buckle up and get ready to embark on an exciting journey into the world of epistemology. By the end of this guide, you'll have a solid foundation to help you navigate and understand the fascinating complexities of knowledge!";

  const audioSrc = audiophil;

  return (
    <Col sm={{ size: 'auto', offset: 1 }}>
       
      <ScrollIndicator />
      <video loop autoPlay muted playsInline>
        <source src={Sartre} type="video/mp4" />
      </video>
      <br />
      <div className="course-paragraph">
        <h3 className="text-center">Utilitarianism</h3>
        <AudioPlayer
            text={text}
            audioSrc={audioSrc}
            timestamps={wordTimestamps}
        />
      </div>
    </Col>
  );
};

export default PhilosophyOfScienceIntroIntroduction;
