import React from "react";
import { Row, Col, Container} from "reactstrap";
import { Router, Route, Switch } from "react-router-dom";
import Card from "../../components/card";
import MetaphysicsImage from '../../assets/metaphysics.svg';
import MetaphysicsIntro from "./metaphysics_introduction/metaphysics_introduction_course_index";
import history from "../../js/history";
import { useTranslation } from "react-i18next";
import { configureI18n } from '../../components/i18nConfig';

configureI18n("/locales/homepage", "translation");

const Metaphysics = () => {

  return (
    <div className="next-steps my-5">
      <Router history={history}>
        <Container className="flex-grow-1 mt-5">
        <Switch>
          <Route exact path="/Metaphysics" component={MetaphysicsHome} />         
          <Route path="/Metaphysics/Metaphysics-intro" component={MetaphysicsIntro} />
        </Switch>
        </Container>
      </Router>
    </div>
    );
  };

const MetaphysicsHome = () => {

  const { t } = useTranslation();

  return (
    <div className="next-steps my-5">
      <h2 className="my-5 text-center">{t("MetaphysicsCoursesPageTitle")}</h2>
      <Row className="d-flex justify-content-between">
        <Col md={6} className="mb-4">
          <Card 
            link="/Metaphysics/Metaphysics-intro" 
            image={MetaphysicsImage} 
            title={t("MetaphysicsCoursesPageIntroCourseTitle")}
            className="mb-3"
          />
        </Col>
      </Row>
    </div>
  );
}

export default Metaphysics;

