import React from "react";
import QuizBee from "../../../components/quiz/index";
import { Col} from "reactstrap";
const Quiz1 = () => {

return (
  <Col sm={{ size: 'auto', offset: 1 }}>
    <QuizBee />
  </Col>
  )
}

export default Quiz1;