import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import { NavLink as RouterNavLink } from 'react-router-dom';

class PhilosopherCard extends Component {
    videoRef = React.createRef();

    handleMouseEnter = () => {
        const video = this.videoRef.current;
        video.play();
    }

    handleMouseLeave = () => {
        const video = this.videoRef.current;
        video.pause();
    }

    render() {
      
        return (
            <NavLink 
                className="text-center overlay" 
                tag={RouterNavLink} 
                to={this.props.link} 
                exact 
                onMouseEnter={this.handleMouseEnter} 
                onMouseLeave={this.handleMouseLeave}
            >
                <div className="overflow">
                    <video 
                        ref={this.videoRef}
                        className="card-img-top rounded-circle"
                        src={this.props.video} 
                        alt=""
                        muted
                    /> 
                    <div className="card-body text-dark">
                        <h2 className="card-title">{this.props.text}</h2>
                    </div>
                </div>
            </NavLink>
        );
    }
}

export default PhilosopherCard;
