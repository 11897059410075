import React, { useState, useEffect, useContext } from "react";
import { Container, TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import { AuthContext } from "../components/AuthContext"; // import the AuthContext
import classnames from 'classnames';
import Products from "../components/Products";
import { collection, query, where, getDocs, getDoc, collectionGroup } from 'firebase/firestore';

import { db, createPortalLink, createPortalLinkResponse } from "./firebase";

const Profile = () => {
  const { user } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState('1'); // initialize activeTab state

  if (!user) {
    return <div>Loading...</div>;
  }

  // Rest of your component code...

  // toggle function to update the activeTab state
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  // Calculate progress
  const keys = Object.keys(localStorage);
  const progressKeys = keys.filter(key => key.includes("_progress_"));
  const progress = progressKeys.length * 10;

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <Col md={2}>
          <img
            src={user.photoURL} // change user.picture to user.photoURL
            alt="Profile"
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
          />
        </Col>
        {user.displayName}
        <button onClick={async () => {
          console.log('5')
          try {
            const data = await createPortalLink({
              returnUrl: window.location.origin,
              locale: "auto",
            });

            console.log({ data })
            window.location.assign(data.data.url);



          } catch (err) {
            console.log({ err })

          }


        }}   >  Manage subscription </button>
      </Row>
      <Products />
      <Row>
      </Row>
    </Container>
  );
};

export default Profile;
