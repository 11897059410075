export const getProgress = (key, userId) => {
  const progressKey = `${userId}_${key}`;
  return localStorage.getItem(progressKey);
};

export const setProgress = (url, progress, userId) => {
  const key = `${userId}_progress_${url}`;
  const existingProgress = localStorage.getItem(key);

  // If there is no existing progress, save the new progress.
  if (!existingProgress) {
    localStorage.setItem(key, progress);
  }
};

