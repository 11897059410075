import React from "react";
import {Button} from "reactstrap";
import { useTranslation } from "react-i18next";
import { configureI18n } from '../i18nConfig';

configureI18n("/locales/homepage", "quizResult");

const Result = ({score, playAgain}) => {
    const { t } = useTranslation('quizResult');

    return (
        <div className="score-board">
            <div className="score">
                <p> {score} / {t('correctScore')}</p>
            </div>
            <Button variant="outline-primary" onClick={playAgain}>
                {t('playAgain')}
            </Button>
        </div>
    )
}

export default Result;