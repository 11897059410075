import React from "react";
import { Row, Col, Container} from "reactstrap";
import { Router, Route, Switch } from "react-router-dom";
import Card from "../../components/card";
import AestheticsImage from '../../assets/aesthetics.svg';
import AestheticsIntro from "./aesthetics_introduction/aesthetics_introduction_course_index";
import history from "../../js/history";
import { useTranslation } from "react-i18next";
import { configureI18n } from '../../components/i18nConfig';

configureI18n("/locales/homepage", "translation");

const Aesthetics = () => {

return (
  <div className="next-steps my-5">
    <Router history={history}>
      <Container className="flex-grow-1 mt-5">
      <Switch>
        <Route exact path="/Aesthetics" component={AestheticsHome} />         
        <Route path="/Aesthetics/Aesthetics-intro" component={AestheticsIntro} />
      </Switch>
      </Container>
    </Router>
  </div>
  );
};

const AestheticsHome = () => {

  const { t } = useTranslation();

  return (
    <div className="next-steps my-5">
      <h2 className="my-5 text-center">{t("AestheticsCoursesPageTitle")}</h2>
      <Row className="d-flex justify-content-between">
        <Col md={6} className="mb-4">
          <Card 
            link="/Aesthetics/Aesthetics-intro" 
            image={AestheticsImage} 
            title={t("AestheticsCoursesPageIntroCourseTitle")}
            className="mb-3"/>
        </Col>
      </Row>
    </div>
  )
}

export default Aesthetics;
