import React, { useEffect, useState } from "react";
import QuizBee from "../../../components/quiz/index";
import { Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { configureI18n } from '../../../components/i18nConfig';

configureI18n("/locales/homepage", "QuizTitle");
 
const Quiz2 = () =>  {

    const { t } = useTranslation('QuizTitle');

    const [questions, setQuestions] = useState([]);

    const { i18n } = useTranslation();

    useEffect(() => {
        const language = i18n.language; // Get current language
        // Adjust the fetch URL based on the language
        const url = `${process.env.PUBLIC_URL}/course-quizes/epistemology_quizes/epistemology_Introduction_quizes/chapter1_quiz_${language}.json`;
        fetch(url)
        .then(response => response.json())
        .then(data => {
            console.log('Fetched data:', data);
            setQuestions(data);
        })
        .catch(error => console.error(error));
    }, [i18n.language]); // Add a dependency on the language so the useEffect hook will run again if the language changes
    // You can retrieve the score from localStorage here
    const score = localStorage.getItem('Name');
  
    if (score === 4) {
        console.log(score);
    }

    return (
        <Col className="quiz" sm={{ size: 'auto', offset: 1 }}>
            <h1>{t("QuizTitle2")}</h1>
            <QuizBee questions={questions} />
        </Col>
    )
}

export default Quiz2;