import React, { useState, useEffect } from "react";
import { Col, Collapse, Navbar, NavbarToggler, Nav, NavItem, NavLink } from 'reactstrap';
import { NavLink as RouterNavLink, useHistory } from "react-router-dom";
import Accordian from "../../../../components/Accordian/Accordian";
import { useTranslation } from "react-i18next";
import { configureI18n } from '../../../../components/i18nConfig';
import useAuth from '../../../../components/useAuth';
import toast from 'react-hot-toast';

configureI18n("/locales/homepage", "CourseNav");

const EpistemologyIntroNav = () => {
  const [isOpenNav, setIsOpenNav] = useState(false);
  const [isOpenAccordian, setIsOpenAccordian] = useState(null);
  const { role } = useAuth();
  const history = useHistory();

  const toggleNav = () => setIsOpenNav(!isOpenNav);
  const toggleAccordian = (i) => {
    if (isOpenAccordian === i) {
      setIsOpenAccordian(null);
    } else {
      setIsOpenAccordian(i);
    }
  };
  
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleWindowResize = () => setWindowWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const { t } = useTranslation('CourseNav');

  const handleNavClick = (e, i) => {
    e.preventDefault();
    if (role === 'Basic' || role === 'Premium') {
      history.push(`/Epistemology/Epistemology-intro/chapter${i}`);
    } else {
      toast.error('Subscribe to continue');
      history.push('/Profile');
    }
  };
  const renderChapters = () => {
    const chapters = [];
  
    for (let i = 1; i <= 8; i++) {
      chapters.push(
        <NavItem key={i}>
          <Accordian 
            title={`Chapter ${i}`} 
            isOpen={isOpenAccordian === `chapter${i}`} 
            toggle={() => toggleAccordian(`chapter${i}`)}
          >
            {
              (i === 1 || role === 'Basic' || role === 'Premium') ? (
                <>
                  <NavLink tag={RouterNavLink} to={`/Epistemology/Epistemology-intro/chapter${i}`}>
                    {t(`NavChapter${i}`)}
                  </NavLink>
                  {i === 1 &&
                    <NavLink tag={RouterNavLink} to={`/Epistemology/Epistemology-intro/quiz${i}`} title={`quiz${i}`}>
                      {`Quiz ${i}`}
                    </NavLink>
                  }
                </>
              ) : (
                <>
                  <NavLink onClick={() => history.push('/Profile')}>
                    {t(`NavChapter${i}`)}
                  </NavLink>
                  <NavLink onClick={() => history.push('/Profile')} title={`quiz${i}`}>
                    {`Quiz ${i}`}
                  </NavLink>
                </>
              )
            }
          </Accordian>
        </NavItem>
      );
    }
  
    return chapters;
  };
  
  
  const AccordionMenu = () => (
    <Nav className="mr-auto vertical-nav" navbar>
      {renderChapters()}
    </Nav>
  );

  const MobileView = () => (
    <Navbar className="chapterNavMobile" color="light" light expand="md">
      <NavbarToggler onClick={toggleNav} />
      <Collapse isOpen={isOpenNav} navbar>
        <Nav className="mr-auto vertical-nav" navbar>
          <NavItem>
            <RouterNavLink to="/Epistemology/Epistemology-intro">
              <NavLink>{t("NavCourseOutline")}</NavLink>
            </RouterNavLink>
            <RouterNavLink to="/Epistemology/Epistemology-intro/introduction">
              <NavLink>{t("NavCourseIntroduction")}</NavLink>
            </RouterNavLink>
          </NavItem>
        </Nav>
      </Collapse>
      <h3 style={{textAlign: 'center', right: isOpenNav ? '0' : '25px' }}>Chapters</h3>
      <Collapse isOpen={isOpenNav} navbar>
        <AccordionMenu />
      </Collapse>
    </Navbar>
  );
  
  const DesktopView = () => (
    <Col>
      <nav>
        <NavItem>
          <NavLink tag={RouterNavLink} to="/Epistemology/Epistemology-intro">
            {t("NavCourseOutline")}
          </NavLink>
        </NavItem>
          <Accordian 
          title={t("NavCourseIntroduction")} 
          isOpen={isOpenAccordian === 'intro'} 
          toggle={() => toggleAccordian('intro')}
        >
          <NavLink tag={RouterNavLink} to="/Epistemology/Epistemology-intro/introduction">
            {t("NavCourseIntroduction")}
          </NavLink>
        </Accordian>
        <AccordionMenu />
      </nav>
    </Col>
  );
  
  return windowWidth <= 991 ? <MobileView /> : <DesktopView />;
  
};

export default EpistemologyIntroNav;