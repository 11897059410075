import React from "react";
import { Row, Col, Container } from "reactstrap";
import { Router, Route, Switch } from "react-router-dom";
import Card from "../../components/card";
import PhilosophyOfMindImage from '../../assets/philosophyofmind.svg'
import PhilosophyOfMindIntro from "./philosophyofmind_introduction/philosophyofmind_introduction_course_index";
import { useAuth } from "../../components/useAuth";
import history from "../../js/history";
import { getProgress } from "../../components/Progress/Progress-check";
import { useTranslation } from "react-i18next";
import { configureI18n } from '../../components/i18nConfig';

configureI18n("/locales/homepage", "translation");

const PhilosophyOfMind = () => {

  return (
    <div className="next-steps my-5">
      <Router history={history}>
        <Container className="flex-grow-1 mt-5">
          <Switch>
            <Route exact path="/Philosophy-of-mind" component={PhilosophyOfMindHome} />
            <Route path="/Philosophy-of-mind/Philosophy-of-mind-intro" component={PhilosophyOfMindIntro} />
          </Switch>
        </Container>
      </Router>
    </div>
  );
};

const PhilosophyOfMindHome = () => {

  const { t } = useTranslation();

  const { isAuthenticated } = useAuth();
  const keys = Object.keys(localStorage).filter(key => key !== 'urls');
  console.log(keys);
  const progress = keys.length * 10;
  const progressString = `Progress: ${getProgress('/Ethics/Ethics-intro/chapter1') + progress}%`;
  const progressNumber = parseInt(progressString.split(':')[1]);
  return (
    <div className='next-steps my-5'>
      <h2 className='my-5 text-center'>{t("PhilosophyOfMindCoursesPageTitle")}</h2>

      <Row className='d-flex justify-content-between'>
        <Col md={6} className='mb-4'>
          <Card
            value={isAuthenticated ? progressNumber : null}
            link='/Philosophy-of-mind/Philosophy-of-mind-intro'
            image={PhilosophyOfMindImage}
            title={t("PhilosophyOfMindCoursesPageIntroCourseTitle")}
            isAuthenticated={isAuthenticated}
            className='mb-3'
          />
        </Col>
      </Row>
    </div>
  );
};

export default PhilosophyOfMind;
