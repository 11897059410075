import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { selectUser } from './userSlice';
import { getAuth, signInWithRedirect, signOut, onAuthStateChanged, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore, doc, setDoc, collection, query, where, onSnapshot, getDocs, addDoc, getDoc } from 'firebase/firestore';
import { loadStripe } from '@stripe/stripe-js';
import app from '../js/firebase';

// Initialize Firestore outside of the hook
export const db = getFirestore(app);

export const useAuth = () => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);  // State for products
  const [role, setRole] = useState('');  // State for products

  const auth = getAuth(app);

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setIsLoading(false);
      //console.log({ user })

      // Save the user in Firestore
      if (user) {
        const userDoc = doc(db, 'users', user.uid);
        setDoc(
          userDoc,
          {
            displayName: user.displayName,
            email: user.email,
            photoURL: user.photoURL,
          },
          { merge: true }
        )
          .catch((error) => {
            //console.error('Error updating user: ', error);
          });
      }
    });



    // Cleanup subscriptions on unmount
    return () => {
      unsubscribeAuth();
    };
  }, [auth, db]);


  const getProducts = async () => {
    try {
      const productss = {}

      const productsQuery = query(collection(db, 'products'), where('active', '==', true));
      const querySnapshot = await getDocs(productsQuery);
      querySnapshot?.forEach(async (product) => {
        // console.log(product.id, ' => ', product.data());
        //console.log('1', { productss })


        productss[product.id] = product.data()
        //console.log('2', { productss })

        // prices collection ref
        const pricesQuery = query(collection(product.ref, 'prices'));

        const priceSnapshot = await getDocs(pricesQuery);

        // getting prices
        priceSnapshot.forEach((price) => {
          // console.log(price.id, ' => ', price.data());
          // console.log('1', { prices })

          productss[product.id].prices = {
            priceId: price.id,
            priceData: price.data()
          }
          // console.log('2', { prices })

        });

      }


      );

      setProducts(productss)
    } catch (error) {
      //console.error('Error fetching products:', error);
    }
  };

  useEffect(() => {
   // console.log('ok')
    getProducts()
  }, [])
  //console.log({ products })

  // useEffect(() => {
  //   console.log({ products })
  //   console.log({ prices })

  // }, [products])


  const user2 = useSelector(selectUser);


  const loadCheckout = async (priceId) => {
    // const docRef = await db
    //   .collection("customers")
    //   .doc(user2.uid)
    //   .collection("checkout_sessions")
    //   .add({
    //     price: priceId,
    //     success_url: window.location.origin,
    //     cancel_url: window.location.origin
    //   });
    //console.log({ priceId })
    //console.log(user?.uid)

   // console.log('10')

    try {
      const docc = await addDoc(collection(db, 'customers', user?.uid, 'checkout_sessions'), {
        price: priceId,
        success_url: window.location.origin,
        cancel_url: window.location.origin
      })

      //console.log('10')
      onSnapshot(docc, (async (snap) => {
        //console.log('11')

        const { error, sessionId } = snap.data();
        //console.log('12', sessionId, snap.data())

        if (error) {
          // Handle error
          //console.log({ error })
        }

        if (sessionId) {
          const stripe = await loadStripe("pk_test_51MmwRmKsgJwTRy54wNrmPovUHUiVVTFDzuzIj4G72nSBEdS2XSiwyF5Og2BQcZDkix6AvZOF1awjJOBUDzk2cuI200yvgqPgWX");
          // Use the stripe object as needed
          stripe.redirectToCheckout({ sessionId })
        }
      }));
    } catch (err) {
     // console.log({ err })
    }



  };


  // fetch data 

  const fetchData = async () => {
    //console.log('1')

    const userUid = user.uid;
    //console.log('22')
    try {
     // console.log('23', user)

      const customersCollectionRef = doc(db, 'customers', userUid);
      //console.log('24')

      // Replace 'userUid' with the actual user's UID you want to retrieve data for

      // Get a reference to the subscription collection for the specified user

      const subscriptionCollectionRef = collection(customersCollectionRef, 'subscriptions');
      //console.log('25')

      const data = await getDocs(subscriptionCollectionRef)
      //console.log({ data })
      const dataa = data?.forEach(async (doc) => {
       // console.log('collection !!', doc.id, doc.data().role)
        const rolee = doc.data().role;
        setRole(rolee)
      }
      )

    } catch (err) {

      //console.log({ err })
    }

  }

  useEffect(() => {

    fetchData()

  }, [user])

  const login = () => {
    signInWithRedirect(auth, new GoogleAuthProvider());
  };

  const logout = () => {
    signOut(auth);
  };

  return {
    user,
    isAuthenticated: !!user,
    isLoading,
    login,
    logout,
    products,  // Include products in the returned object
    loadCheckout,
    role
  };
};

export default useAuth;