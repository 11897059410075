import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Container } from 'reactstrap';
import history from "../../../js/history";
import EpistemologyIntroNav from "./nav/epistemology_introduction_nav"
import useAuth from '../../../components/useAuth';
import EpistemologyIntroIntroduction from "../../../courses/epistemology/epistemology_introduction/epistemology_introduction_introduction";
import EpistemologyIntroChapter1 from "../../../courses/epistemology/epistemology_introduction/epistemology_introduction_chapter1";
import EpistemologyIntroChapter2 from "../../../courses/epistemology/epistemology_introduction/epistemology_introduction_chapter2";
import EpistemologyIntroChapter3 from "../../../courses/epistemology/epistemology_introduction/epistemology_introduction_chapter3";
import EpistemologyIntroChapter4 from "../../../courses/epistemology/epistemology_introduction/epistemology_introduction_chapter4";
import EpistemologyIntroChapter5 from "../../../courses/epistemology/epistemology_introduction/epistemology_introduction_chapter5";
import EpistemologyIntroChapter6 from "../../../courses/epistemology/epistemology_introduction/epistemology_introduction_chapter6";
import EpistemologyIntroChapter7 from "../../../courses/epistemology/epistemology_introduction/epistemology_introduction_chapter7";
import EpistemologyIntroChapter8 from "../../../courses/epistemology/epistemology_introduction/epistemology_introduction_chapter8";
import Quiz1 from "../../../courses/epistemology/epistemology_introduction/epistemology_introduction_quiz1";
import Quiz2 from "../../../courses/epistemology/epistemology_introduction/epistemology_introduction_quiz2";
import Quiz3 from "../../../courses/epistemology/epistemology_introduction/epistemology_introduction_quiz3";
import Quiz4 from "../../../courses/epistemology/epistemology_introduction/epistemology_introduction_quiz4";
import Quiz5 from "../../../courses/epistemology/epistemology_introduction/epistemology_introduction_quiz5";
import Quiz6 from "../../../courses/epistemology/epistemology_introduction/epistemology_introduction_quiz6";
import Quiz7 from "../../../courses/epistemology/epistemology_introduction/epistemology_introduction_quiz7";
import Quiz8 from "../../../courses/epistemology/epistemology_introduction/epistemology_introduction_quiz8";
import IntroCard from "../../../components/IntroCard";
import Sartre from "./assets/sartre.jpg";
import EdmundGettier from "./assets/EdmundGettier.jpg";
import GEMoore from "./assets/GE-moore.jpg";
import WilfridSellars from "./assets/WilfridSellars.jpg";
import RobertNozick from "./assets/RobertNozick.jpg";
import MichaelHuemer from "./assets/MichaelHuemer.jpg";
import { useTranslation } from "react-i18next";
import { configureI18n } from '../../../components/i18nConfig';

configureI18n("/locales/homepage", "epistemology_introduction_course_index");

const EpistemologyIntroCourseIndex = () => {
  
  const { role } = useAuth(); // get the user's role

return (
<Router history={history}>
<EpistemologyIntroNav />
<Switch>
  <Route exact path="/Epistemology/Epistemology-intro" component={EpistemologyIntro} />
  <Route path="/Epistemology/Epistemology-intro/introduction" component={EpistemologyIntroIntroduction} />  
  <Route path="/Epistemology/Epistemology-intro/chapter1" component={EpistemologyIntroChapter1} /> 
  <Route path="/Epistemology/Epistemology-intro/quiz1" component={Quiz1} />

  {/* Check the user's role and redirect them if they don't have the right role for other chapters and quizzes */}
  {role === 'Basic' || role === 'Premium' ? (
    <>
      <Route path="/Epistemology/Epistemology-intro/chapter2" component={EpistemologyIntroChapter2} />
      <Route path="/Epistemology/Epistemology-intro/quiz2" component={Quiz2} />
        <Route path="/Epistemology/Epistemology-intro/chapter3" component={EpistemologyIntroChapter3} /> 
        <Route path="/Epistemology/Epistemology-intro/quiz3" component={Quiz3} />         
        <Route path="/Epistemology/Epistemology-intro/chapter4" component={EpistemologyIntroChapter4} /> 
        <Route path="/Epistemology/Epistemology-intro/quiz4" component={Quiz4} />     
        <Route path="/Epistemology/Epistemology-intro/chapter5" component={EpistemologyIntroChapter5} /> 
        <Route path="/Epistemology/Epistemology-intro/quiz5" component={Quiz5} />
        <Route path="/Epistemology/Epistemology-intro/chapter6" component={EpistemologyIntroChapter6} /> 
        <Route path="/Epistemology/Epistemology-intro/quiz6" component={Quiz6} />        
        <Route path="/Epistemology/Epistemology-intro/chapter7" component={EpistemologyIntroChapter7} /> 
        <Route path="/Epistemology/Epistemology-intro/quiz7" component={Quiz7} />   
        <Route path="/Epistemology/Epistemology-intro/chapter8" component={EpistemologyIntroChapter8} /> 
        <Route path="/Epistemology/Epistemology-intro/quiz8" component={Quiz8} />         
      {/* ... other routes here ... */}
    </>
  ) : (
    <Redirect to="/Profile" />
  )}
</Switch>
</Router>


  );
};

const EpistemologyIntro = () => {
  const { t } = useTranslation('epistemology_introduction_course_index');

//Course introduction
  return (
  <div className="intro-container">
    <Container className="timeline-container">
    <h2 className="text-center">{t("CourseIntroductionTitle")}</h2>
    <p className="text-center">{t("CourseIntroductionText")}</p>
    <h3 className="text-center">{t("CourseOverview")}</h3>
        <div className="timeline timeline-centered">
          <IntroCard 
            chapter={"chapter 1"} 
            image={EdmundGettier}
            title={t("Chapter1Title")}
            text={t("Chapter1Text")} 
            link="/Epistemology/Epistemology-intro/chapter1"
          />
          <IntroCard 
            chapter={"chapter 2"} 
            image={GEMoore}
            title={t("Chapter2Title")}
            text={t("Chapter2Text")}
            link="/Epistemology/Epistemology-intro/chapter2" 
          />
          <IntroCard 
            chapter={"chapter 3"} 
            image={WilfridSellars}
            title={t("Chapter3Title")}
            text={t("Chapter3Text")}
            link="/Epistemology/Epistemology-intro/chapter3"           
          />
          <IntroCard 
            chapter={"chapter 4"} 
            image={RobertNozick}
            title={t("Chapter4Title")}
            text={t("Chapter4Text")}
            link="/Epistemology/Epistemology-intro/chapter4"   
          />
          <IntroCard 
            chapter={"chapter 5"} 
            image={Sartre}
            title={t("Chapter5Title")}
            text={t("Chapter5Text")}
            link="/Epistemology/Epistemology-intro/chapter5"    
          />
          <IntroCard 
            chapter={"chapter 6"} 
            image={Sartre}
            title={t("Chapter6Title")}
            text={t("Chapter6Text")}
            link="/Epistemology/Epistemology-intro/chapter6"    
          />
          <IntroCard 
            chapter={"chapter 7"} 
            image={MichaelHuemer}
            title={t("Chapter7Title")}
            text={t("Chapter7Text")}
            link="/Epistemology/Epistemology-intro/chapter7"
          />
          <IntroCard 
            chapter={"chapter 8"} 
            image={Sartre}
            title={t("Chapter8Title")}
            text={t("Chapter8Text")}
            link="/Epistemology/Epistemology-intro/chapter8" 
          />
           <IntroCard 
            chapter={"chapter 9"} 
            image={Sartre}
            title={t("Chapter9Title")}
            text={t("Chapter9Text")} 
            link="/Epistemology/Epistemology-intro/chapter9"
          />
            <IntroCard 
            chapter={"chapter 10"} 
            image={Sartre}
            title={t("Chapter10Title")}
            text={t("Chapter10Text")}
            link="/Epistemology/Epistemology-intro/chapter10"
          />
        </div>
    </Container>
  </div>
  )
}

export default EpistemologyIntroCourseIndex;

